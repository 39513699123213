import { ReadyOrders } from './ReadyOrders';
import { channelId } from '../App';
import { useApiCall } from '../utils/useApiCall';
import { FailureMessage } from '../views/FailureMessage';

export type DisplayMode = 'auto' | 'manual';

export interface IDisplay {
  mode: DisplayMode;
  enabled: boolean;
  color: string;
  duration: number;
}
export interface IOrderReadyScreenConfig {
  display: IDisplay;
  locationName: string;
}

export const OrderReadyScreenConfig = () => {
  const {
    isLoading,
    error,
    response: orderReadyScreenConfig,
  } = useApiCall<IOrderReadyScreenConfig>(
    `/api/v1/public/order-ready-screen/${channelId}/config`,
    [],
  );

  if (isLoading && !error) {
    return <FailureMessage message="Loading..." />;
  }

  return (
    <div>
      {error ? (
        <FailureMessage message={error} />
      ) : (
        <div>
          {orderReadyScreenConfig.display?.enabled ? (
            <ReadyOrders orderReadyScreenConfig={orderReadyScreenConfig} />
          ) : (
            <FailureMessage message="Order Ready Screen is not enabled..." />
          )}
        </div>
      )}
    </div>
  );
};
